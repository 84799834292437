/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import wrapWithProvider from "./src/store/provider"
// export const wrapRootElement = wrapWithProvider

import "./src/styles/global.css"
